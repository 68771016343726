/*Represents the API endpoint for authentication.*/
export const requestURI = {
  "login.auth": "api/auth/login",
  "refresh.auth.token": "api/auth/refresh",
  "logout": "api/auth/logout",
  "token.verify": "api/auth/token",
  "stream.list": "streams/list",
  "streams.listwithoutcomponents":"streams/listWithoutComponents",
  "stream.count": "streams/count",
  "stream.chart": "streams/dayWiseStats",
  "stream.stats": "streams/cumulativeStats",
  "stream.detections": "streams/detections",
  "stream.content": "streams/id",
  "disable.content": "content/disable",
  "enable.content": "content/enable",
  "dashboard.list": "streams/list",
  "dashboard.count": "streams/count",
  "dashboard.filter": "dashboard/filter",
  "dashboard.widgetdata": "dashboard/widget/data",
  "dashboard.details": "dashboard/details",
  "dashboard.widgetdetails": "dashboard/widget/details",
  "create.group": "configuration/create/securitygroup",
  "create.role": "configuration/create/securityrole",
  "create.user": "configuration/create/securityuser",
  "create.tenant": "configuration/create/tenant",
  "list.group": "configuration/list/securitygroup",
  "list.role": "configuration/list/securityrole",
  "list.user": "configuration/list/securityuser",
  "list.tenant": "configuration/list/tenant",
  "list.region": "configuration/list/regions",
  "list.saved.settings": "configuration/list/withbody/streamsettings",
  "savesetting": "configuration/create/streamsettings",
  "list.parameters": "content/streamparameters",
  "list.url": "configuration/list/securityurl",
  "list.stream": "streams/list",
  "list.organization": "configuration/list",
  "update.organization": "configuration/update/",
  "update.savedsettings": "configuration/update/streamsettings",
  "list.columns": "content/autoSuggest",
  "delete.organization": "configuration/delete",
  "delete.setting": "configuration/delete/streamsettings",
  "total.organization": "configuration/count",
  "dashboard.list.events": "outlier/events",
  "dashboard.stream.details": "streams/id",
  "dashboard.detection.meta": "outlier/detectionMeta",
  "dashboard.entity.meta": "outlier/entityMeta",
  "dashboard.detection.list": "outlier/detection",
  "dashboard.entity.list": "outlier/listEntity",
  "dashboard.detection.graph": "attackpath/artifactgraph",
  "dashboard.entity.garph.query": "graph/query",
  "get.incidentid": "incident/incident",
  "get.entity.incidentid": "incident/entityincident",
  "create.incident": "incident/create",
  "incident.overview": "incident/overview",
  "incident.activites": "incident/activities",
  "incident.events": "incident/events",
  "incident.comment": "incident/comments",
  "incident.update": "incident/update",
  "incident.statuslist": "incident/liststatus",
  "incident.reopen": "incident/reopen",
  "incident.delete": "incident/discard",
  "search.search": "search/datalake",
  "search.autosuggest": "search/autoSuggest",
  "search.trend.chart": "search/searchTrends",
  "detection.trend.chart": "outlier/detectionTrends",
  "entity.trend.chart": "outlier/entityTrends",
  "search.create": "search/saved/create",
  "search.list": "search/saved/list",
  "search.update": "search/saved/update",
  "search.remove": "search/saved/remove",
  "terminal.command": "contentcmd/terminal",
  "terminal.save.command": "contentcmd/save",
  "compile.command": "contentcmd/compile",
  "get.artifact.list": "search/list/artifacts",
  "get.artifact.count": "search/count/artifact",
  "get.artifact.details": "search/data/artifact",
  "playbook.automation": "automation/list/playbooks",
  "playbook.automation.time": "automation/playbook/executiontime",
  "get.freetext.query": "search/queryconvertor/query",
  "get.table.schema": "search/schema",
  "playbook.execute": "automation/playbook/execute",
  "playbook.tasks": "automation/playbook/tasks",
  "playbook.taskdetails": "automation/playbook/taskdetails",
  "onboard.signup": "onboard/signup",
  "onboard.verify": "onboard/verify",
  "onboard.checktoken": "onboard/checktoken",
  "automation.taskmap": "automation/playbook/taskmap",
  "invistigation.incidentlist": "investigation/getincidentlist",
  "invistigation.incidentdetails": "investigation/getincidentdetail",
  "invistigation.listentity": "investigation/listentity",
  "investigation.detectionlist": "investigation/getdetectionlist",
  "investigation.summary": "investigation/overview",
  "rawdata": "search/extractRaw",
  "badge.create": "badge/create",
  "badge.list": "badge/list",
  "badge.remove": "badge/remove",
  "badge.getBadges": "badge/getBadges",
  "badge.update": "badge/update",
  "content.id": "content/id",
  "entity.groups": "streams/entity/groups",
  "entity.group.values": "streams/entity/group/values",
  "streams.id": "streams/id",
  "content.list": "content/list",
  "collectors.loadactivenode": "collector/listactivenodes",
  "collectors.getiniconfig": "collector/getConfig",
  "collectors.saveconfig": "collector/saveconfig",
  "collectors.eps":"collector/getEps",
  "collectors.totaltoday":"collector/totalToday",
  "download.listfiles":"download/listfiles",
  "download.fetch":"download/fetch",
  "streams.summmary":"search/llmsummary",
  "dashboard.detection.query":"outlier/detection/query",
  "dashboard.detection.resolve":"outlier/resolveDetection",
  "collector.encrypt":"collector/encrypt",
  "health.redis":"search/redisdata",
  "health.postgress":"search/postgressdata",
  "collector.instruction":"collector/instructions",
  "collector.suggest":"collector/suggest",
  "stream.rawevents":"streams/rawevents",
  "fetech.results.api":"search/result",
  "delete.queryid.api":"search/cancel",
  "rawevents.trend.graph.api":"streams/rawevents/trend",
  "loginauth.google":"loginauth/google"
};
