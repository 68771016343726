import { flatten } from "flat";
import moment from "moment";
import React, { useState } from "react";
import { Stream } from "../StreamScript";

function RawEventLayout(props) {
  let output = props.data.message;
  let detectionData = output;
  let rawmessage = props.data.rawmessage;
  let messageid = output.messageid;
  const processedOutputData = flatten(output);

  const outputarrafterfive = [];
  const outputarrbeforefive = [];

  var counter = 0;
  const {
    timestamp,
    messageid: messageIdVar,
    ...requireDetectionData
  } = detectionData;
  var mapkeys = Object?.keys(requireDetectionData);

  for (let index = 0; index < mapkeys.length; index++) {
    const key = mapkeys[index];
    // const element = processedOutputData[key];

    if (index > 5) {
      outputarrafterfive.push([key, processedOutputData[key]]);
    } else {
      outputarrbeforefive.push([key, processedOutputData[key]]);
    }
  }

  Object?.keys(processedOutputData)?.map((key) => (counter = counter + 1));

  const detectionDate = moment(new Date(output.timestamp)).format(
    "DD  MMM hh:mm:ss"
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 450; // Set your desired character limit for the truncated text

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="row" id="bxRawEvents">
        <div className="col-12">
          <div className="row bxStreamEventDetection m-0 mb-1 d-flex align-items-center">
            <div className="bxSEDHead bx_step p-2 d-flex align-items-center justify-content-between">
              <p className="timer_box text-success">{detectionDate}</p>
              <div className="dropdown">
                <button
                  className="dropdown-toggle arrow-none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <i className="material-symbols-outlined text-white">
                    more_vert
                  </i>
                </button>
                <ul className="dropdown-menu streamDots">
                  <h6 className="ps-2 border-bottom pb-2">Explore</h6>
                  <li
                    className="bageEntity d-flex align-items-center"
                    onClick={(event) =>
                      Stream.openRawEventsTab(
                        event,
                        "bnfxparsedevents",
                        "bnfxrawevents",
                        output.messageid
                      )
                    }
                  >
                    <span className="material-symbols-outlined">
                      repartition
                    </span>
                    Parsed
                  </li>
                  <li
                    className="bageDetection d-flex align-items-center"
                    onClick={(event) =>
                      Stream.openRawEventsTab(
                        event,
                        "bnfxrawevents",
                        "bnfxparsedevents",
                        output.messageid
                      )
                    }
                  >
                    <span className="material-symbols-outlined">
                      key_visualizer
                    </span>
                    RawEvent
                  </li>
                </ul>
              </div>

{/* <div class="custom-tooltip-container">
      <div class="custom-hover-btn">
        <span class="material-symbols-outlined icon">more_vert</span>
      </div>

      <div class="custom-toolbar">
        <div
          class="custom-toolbar-item delete mx-2"
          onClick={(event) =>
            Stream.openRawEventsTab(
              event,
              "bnfxparsedevents",
              "bnfxrawevents",
              output.messageid
            )
          }
        >
          <span class="material-symbols-outlined">repartition</span>
          Parsed
        </div>
        <div
          class="custom-toolbar-item publish"
          onClick={(event) =>
            Stream.openRawEventsTab(
              event,
              "bnfxrawevents",
              "bnfxparsedevents",
              output.messageid
            )
          }
        >
          <span class="material-symbols-outlined">key_visualizer</span>
          RawEvent
        </div>
      </div>
    </div> */}

            </div>
            <div className="bxSEDBody col-md-12">
              <div
                className="bxSEDBody_title_new raweventsdata"
                id={"bnfxrawevents" + output.messageid}
              >
                <p className="mt-2 pb-3 bnfxRawmessage">
                  {isExpanded
                    ? rawmessage
                    : `${rawmessage.slice(0, maxLength)}...`}

                  <button
                    type="button"
                    className="actionDataCollapse_btn float-end"
                    onClick={toggleShowMore}
                  >
                    {isExpanded ? "SHOW LESS" : "SHOW MORE"}
                    <span className="material-symbols-outlined actionDataCollapse_Btn">
                      {isExpanded
                        ? "keyboard_double_arrow_up"
                        : "keyboard_double_arrow_down"}
                    </span>
                  </button>
                </p>
              </div>
              <div
                className="bxSEDBody_title_new raweventsdata active"
                id={"bnfxparsedevents" + output.messageid}
              >
                <div className="bxSEDBodyBox_inner">
                  <div className="tags_foot my-1">
                    {outputarrbeforefive?.map((attr) => (
                      <React.Fragment key={attr[0]}>
                        <>
                          <span className="bxRawEventKey">{attr[0]}</span>
                          <span className="bxEventValue">{attr[1]}</span>
                        </>
                      </React.Fragment>
                    ))}
                  </div>
                  <div
                    className="ExpandCollapseBtn"
                    onClick={(e) => Stream.loadmoredatacoallapse(e.target)}
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapsebxJsonText" + messageid}
                    aria-expanded="false"
                    aria-controls="collapsebxJsonText"
                  >
                    <span className="material-symbols-outlined text-success">
                      expand_more
                    </span>
                  </div>
                </div>
                <div
                  className="bxJsonText collapse"
                  id={"collapsebxJsonText" + messageid}
                >
                  {outputarrafterfive?.map((attr) => (
                    <React.Fragment key={attr[0]}>
                      <>
                        <span className="bxRawEventKey">{attr[0]}</span>
                        <span className="bxEventValue">{attr[1]}</span>
                      </>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RawEventLayout;
