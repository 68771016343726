import React from "react";

import { ajax } from "../Service";
import Pagination from "../pagination/Pagination";

import { t } from "i18next";
import moment from "moment";

import { CommonTemplate } from "../containerAvailability/CommonScript";

import EntityDisplayPanel from "./EntityDisplayPanel";
import { showError } from "../DisplayError";
import NoContent from "../NoContent";
import { setGlobalState } from "../GlobalVariables";
import { Incidence } from "../IncidencePanel/IncidenceScript";
import DashboardEventsList from "./DashboardEventsList";
import DashboardEntityList from "./DashboardEntityList";
import DashboardDetectionList from "./DashboardDetectionList";
import DisplayPanel from "./DisplayPanel";
import TrendChart from "../../highChart/TrendChart";
import { requestURI } from "../RequestURIs";
import { Stream } from "../../../pages/stream/StreamScript";
import ToastPopup from "../ToastPopup";
import { Flow } from "../../flow/FlowScript";
import TimelineEvents from "./TimelineEvents";

export const PanelScript = {
  default_startDate: null,
  default_endDate: null,
  default_timePhrase: null,
  selected_row: null,
  gettimeline_data: null,
  settimeline_data: (gettimeline_data) => {
    PanelScript.gettimeline_data = gettimeline_data;
  },
  setDefaultStartDate: (default_startDate) => {
    PanelScript.default_startDate = default_startDate;
  },

  setDefaultEndDate: (default_endDate) => {
    PanelScript.default_endDate = default_endDate;
  },
  settimePhrase: (day) => {
    PanelScript.default_timePhrase = day;
  },

  loadrowOnclick: (rows, starttime, endtime, timephrase) => {
    PanelScript.setDefaultStartDate(starttime);
    PanelScript.setDefaultEndDate(endtime);
    PanelScript.settimePhrase(timephrase);
    if (rows["type"] === "detection") {
      PanelScript.loadDetectionMeta(rows);
    } else if (rows["type"] === "entity") {
      PanelScript.loadEntityMeta(rows);
    } else if (rows["type"] === "stream") {
      PanelScript.loadStreamDetails(rows["id"], true);
    } else {
      Incidence.loadincidentdetails(rows.id,"fromdashboard");
    }
  },

  clickOnParticularPoint: (dataset, starttime, endtime, timephrase) => {
    PanelScript.setSelectedRow(dataset);
    PanelScript.setDefaultStartDate(starttime);
    PanelScript.setDefaultEndDate(endtime);
    PanelScript.settimePhrase(timephrase);
    PanelScript.loadData(0);
    PanelScript.loadleftpaneltrendgraph();
  },

  loadData: (offset) => {
    let dictionaryname={}
    if (PanelScript.selected_row && PanelScript.selected_row.data && Array.isArray(PanelScript.selected_row.data.uniquekey)) {
      for (let i = 0; i < PanelScript.selected_row.data.uniquekey.length; i++) {
          let key = PanelScript.selected_row.data.uniquekey[i]; 

        if (PanelScript.selected_row.point) {
          dictionaryname[key] = PanelScript.selected_row.point[key]; 
        }else if(PanelScript.selected_row.rows){
          dictionaryname[key] = PanelScript.selected_row.rows[key]; 
        }
      }
  } else {
      console.error('PanelScript.selected_row.data.uniquekey is not defined or not an array');
  }
  
  
    CommonTemplate.renderLoadingIcon("#bnfxtimelineTab")
    let limit = 10;
    let request =null;
    if(PanelScript.selected_row && PanelScript.selected_row.data && PanelScript.selected_row.data.uniquekey){
      request = {
        starttime: PanelScript.default_startDate,
        endtime: PanelScript.default_endDate,
        limit: limit,
        offset: offset ? offset : 0,
        value: PanelScript.selected_row.name,
        column: PanelScript.selected_row.column,
        timePhrase: PanelScript.default_timePhrase,
        keymap:dictionaryname,
        uniquekey:PanelScript.selected_row.data.uniquekey,
        columnmap:PanelScript.selected_row.columnmap
      };
  
    }else{
      request = {
        starttime: PanelScript.default_startDate,
        endtime: PanelScript.default_endDate,
        limit: limit,
        offset: offset ? offset : 0,
        value: PanelScript.selected_row.name,
        column: PanelScript.selected_row.column,
        timePhrase: PanelScript.default_timePhrase,
      };
  
    }
     
    ajax(
      "post",
      requestURI["dashboard.detection.query"],
      null,
      request,
      PanelScript.renderLoadedData,
      showError,
      [limit, offset]
    );
  },
  renderLoadedData: (response, args) => {
    let limit = args[0];
    let offset = args[1];
    let result = response.data.response.result;
    let status=response.data.response.status;
    let eventtotal = response.data.response.total;

    if(status==='failed'){
      const timelinecontainer = document.querySelector("#bnfxtimelineTab");
      const timelinedata = (
        <NoContent
          id={"nodata"}
          title={t("No data found.")}
          description={t("No relevant data could be retrieved for the requested query.")}
        />);
      CommonTemplate.ContainerAvailability(timelinecontainer, timelinedata);
    }else{
      const container = document.querySelector("#bnfxtimelineTab");    
      const data = <TimelineEvents output={result} 
      callback={() => PanelScript.renderTimelinePanelPagination(eventtotal,limit,offset,"timelineeventsPagination")}/>;
      CommonTemplate.ContainerAvailability(container, data);
    }
    
  },
  renderTimelinePanelPagination: (total, limit, offset, id) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: limit,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector("#" + id);
    let data = (
      <Pagination
        pagination={paginationData}
        onClickFn={PanelScript.getTimelineEvents}
      />
    );

    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      data = null;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },
  getTimelineEvents: (offset) => {
    PanelScript.loadData(offset);
  },

  loadevents: (rows, offset) => {
    CommonTemplate.renderLoadingIcon("#timelinelist")
    let limit = 10;
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      limit: limit,
      offset: offset ? offset : 0,
      timePhrase: PanelScript.default_timePhrase,
    };
    if (rows["type"] === "entity") {
      request.entity = "" + rows["entity"];
      request.entitytype = rows["entitytype"];
    } else if (rows["type"] === "detection") {
      request.detectionid = rows.detectionid;
    }

    ajax(
      "post",
      requestURI["dashboard.list.events"],
      null,
      request,
      PanelScript.rendereventslist,
      showError,
      [offset, limit]
    );
  },
  rendereventslist: (result, args) => {
    const offset = args[0];
    const limit = args[1];
    let eventtotal = result.data.response.total;

    const container = document.querySelector("#timelinelist");

    const data = <DashboardEventsList result={result} 
    callback={() => PanelScript.renderPanelPagination(eventtotal,limit,offset,"timelinePagination")}/>;
    CommonTemplate.ContainerAvailability(container, data);
    
  },
  renderPanelPagination: (total, limit, offset, id) => {
    if (offset > 0) {
      return;
    }
    const paginationData = {
      total: total,
      limit: limit,
      offset: offset ? offset : 0,
    };

    const container = document.querySelector("#" + id);
    let data = null;
    if (id === "timelinePagination") {
      data = (
        <Pagination
          pagination={paginationData}
          onClickFn={PanelScript.getPaginationEvents}
        />
      );
    } else if (id === "entityPagination") {
      data = (
        <Pagination
          pagination={paginationData}
          onClickFn={PanelScript.getPaginationEntity}
        />
      );
    } else if (id === "detectionsPagination") {
      data = (
        <Pagination
          pagination={paginationData}
          onClickFn={PanelScript.getPaginationDetection}
        />
      );
    }
    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      data = null;
      CommonTemplate.ContainerAvailability(container, data);
    }
  },
  getPaginationEvents: (offset) => {
    PanelScript.loadevents(PanelScript.selected_row, offset);
  },
  getPaginationEntity: (offset) => {
    PanelScript.loadenitylist(PanelScript.selected_row, offset);
  },
  getPaginationDetection: (offset) => {
    PanelScript.loaddetectionlist(PanelScript.selected_row, offset);
  },

  loadenitylist: (data, offset) => {
    CommonTemplate.renderLoadingIcon("#entitylist")
    let limit = Math.floor(PanelScript.bxOffcanvasHeights() / 93);
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      limit: limit,
      offset: offset ? offset : 0,
      detectionid: data.detectionid,
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };

    ajax(
      "post",
      requestURI["dashboard.entity.list"],
      null,
      request,
      PanelScript.renderentitylist,
      showError,
      [offset, limit, data]
    );
  },
  renderentitylist: (result, args) => {
    const offset = args[0];
    const limit = args[1];
    let entitytotal = result.data.response.total;

    let removePagintion = document.getElementById("entityPagination");

    if (result.data.response.status === "failed") {
      const container = document.querySelector("#entitylist");
      const data = "not entity data found";
      CommonTemplate.ContainerAvailability(container, data);
      removePagintion.classList.remove("d-none");
    } else {
      const container = document.querySelector("#entitylist");

      const data = (
        <DashboardEntityList
          result={result}
          id={args[2]["detectionid"]}
          detectionname={args[2].name}
          streamid={args[2].streamid}
          tenant={args[2].tenant}
          data={args[2]}
          detectiontotal={PanelScript.total}
          callback={() =>
            PanelScript.renderPanelPagination(
              entitytotal,
              limit,
              offset,
              "entityPagination"
            )
          }
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
      // PanelScript.renderPanelPagination(
      //   entitytotal,
      //   limit,
      //   offset,
      //   "entityPagination"
      // );
    }
  },
  loaddetectionlist: (data, offset) => {
    CommonTemplate.renderLoadingIcon("#detectionslist")
    let limit = Math.floor(PanelScript.bxOffcanvasHeights() / 93);
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      limit: limit,
      offset: offset ? offset : 0,
      entity: "" + data["entity"],
      entitytype: data["entitytype"],
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };

    ajax(
      "post",
      requestURI["dashboard.detection.list"],
      null,
      request,
      PanelScript.renderdetectionlist,
      showError,
      [offset, limit, data]
    );
  },
  renderdetectionlist: (result, args) => {
    const offset = args[0];
    const limit = args[1];
    let detectiontotal = result.data.response.total;

    let removePagintion = document.getElementById("detectionsPagination");

    if (result.data.response.status === "failed") {
      const container = document.querySelector("#detectionslist");
      const data = "not event data found";
      CommonTemplate.ContainerAvailability(container, data);
      removePagintion.classList.remove("d-none");
    } else {
      const container = document.querySelector("#detectionslist");
      const data = (
        <DashboardDetectionList
          result={result}
          entity={"" + args[2].entity}
          entitytype={args[2].entitytype}
          streamid={args[2].streamid}
          tenant={args[2].tenant}
          data={args[2]}
          callback={() =>
            PanelScript.renderPanelPagination(
              detectiontotal,
              limit,
              offset,
              "detectionsPagination"
            )
          }
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
      // Dashboard.renderPanelPagination(
      //   detectiontotal,
      //   limit,
      //   offset,
      //   "detectionsPagination"
      // );
    }
  },
  loadStreamDetails: (id) => {
    CommonTemplate.renderLoadingIcon("#bnfxActionList")
    const request = {
      _id: id,
    };
    ajax(
      "post",
      requestURI["dashboard.stream.details"],
      null,
      request,
      PanelScript.renderStreamDetails,
      showError,
      [true]
    );
  },
  renderStreamDetails: (result, args) => {
    let response = result.data.response;
    Stream.loadActionDetails(response, args[0]);
  },
  loadDetectionMeta: (data) => {
    CommonTemplate.renderLoadingIcon("#bnfxActionList")
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      detectionid: data["detectionid"],
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };

    ajax(
      "post",
      requestURI["dashboard.detection.meta"],
      null,
      request,
      PanelScript.renderdetectionmeta,
      showError,
      [data]
    );
  },
  DetectionTabRenderFunction: (tabtype, data) => {
    PanelScript.setSelectedRow(data);
    if (tabtype === "entity") {
      PanelScript.loadenitylist(data);
    } else {
      PanelScript.loadevents(data);
    }
  },
  renderdetectionmeta: (result, args) => {
    PanelScript.loaddetectiontrendgraph(args[0]);

    let output = result.data.response.result[0];

    let details = (({ score, maxtime, criticality, ...rest }) => rest)(output);
    const container = document.querySelector("#bnfxActionList");
    container.setAttribute("data-current-container", "dashboard");

    PanelScript.settotal(output.score);
    let time = moment(new Date(output["maxtime"])).format("DD  MMM hh:mm");
    const data = (
      <DisplayPanel
        total={output.score}
        criticality={output.criticality}
        logo={"troubleshoot"}
        name={args[0].name}
        details={details}
        timestamp={time}
        tab1={"entity"}
        tab2={"timeline"}
        row={args[0]}
        onClickFn={PanelScript.DetectionTabRenderFunction}
      />
    );
    CommonTemplate.ContainerAvailability(container, data);
  },
  settotal: (total) => {
    PanelScript.total = total;
  },
  loadEntityMeta: (data) => {
    CommonTemplate.renderLoadingIcon("#bnfxActionList")
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      entity: "" + data["entity"],
      entitytype: data["entitytype"],
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };

    ajax(
      "post",
      requestURI["dashboard.entity.meta"],
      null,
      request,
      PanelScript.renderentitymeta,
      showError,
      [data]
    );
  },
  renderentitymeta: (result, args) => {
    let output = result?.data?.response?.result[0];
    PanelScript.enitydetails(args[0], output);
  },
  setSelectedRow: (row) => {
    PanelScript.selected_row = row;
  },
  OutlierTabRenderFunction: (tabtype, data) => {
    PanelScript.setSelectedRow(data);
    if (tabtype === "detections") {
      PanelScript.loaddetectionlist(data);
    } else {
      PanelScript.loadevents(data);
    }
  },
  formatTimestamp: (timestamp) => {
    const date = new Date(timestamp);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleString(undefined, options);
  },
  loaddetectiongraph: (
    id,
    entity,
    enitytype,
    title,
    tenant,
    score,
    streamid
  ) => {
    CommonTemplate.renderLoadingIcon("#detectiongraph" + id)
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      entity: "" + entity,
      entitytype: enitytype,
      detectionid: id,
      tenant: tenant,
      streamid: streamid,
      timezone: CommonTemplate.Timezone,
      timePhrase: PanelScript.default_timePhrase,
    };
    ajax(
      "post",
      requestURI["dashboard.detection.graph"],
      null,
      request,
      PanelScript.renderdetectiongraph,
      showError,
      [id, title, score, entity, enitytype, tenant, streamid]
    );
  },
  renderdetectiongraph: (result, args) => {
    let status = result.data.response.status;
    if (status === "failed") {
      const container = document.querySelector("#detectiongraph" + args[0]);
      const data = (
        <NoContent
          id={"noattackpath"}
          title={t("noattackpathfound")}
          description={t("noattackpathfound.description")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      let provider = null;
      let output = result.data.response.result;
      let entitytype = null;
      let entity = null;
      let requestdata={
        id: args[0],
        title: args[1],
        score:args[2],
        tenant: args[5],
        entity:  args[3],
        entitytype: args[4],
        streamid: args[6],
      }
      let incidentrequest = {
        tenant: args[5],
        entity: "" + args[3],
        entitytype: args[4],
        detectionid: args[0],
        streamid: args[6],
      };
      const fieldsToDrop = [
        "id",
        "customer",
        "tenant",
        "name",
        "detectiontime",
        "title",
        "provider",
        "score",
        "tactic",
        "tags",
        "technique",
        "criticality",
        "type",
        "group",
        "streamprovider",
        "streamtype",
        "streamgroup",
        "uncorrelated",
        "streamname",
        "entitytype",
        "derived",
        "artifacts",
        "streamid",
        "detectionname",
        "detectionid",
        "entityvalue",
        "messageid",
        "attachedbadges",
      ];

      const cleanedResponse = {};

      output?.forEach((entry) => {
        const timestamp = entry.detectiontime; // Assuming detectiontime is the timestamp
        const cleanedEntry = { ...entry };

        // Drop specified fields
        entitytype = Array.isArray(cleanedEntry.entitytype)
          ? cleanedEntry["entitytype"][0]
          : cleanedEntry["entitytype"];
        entity = Array.isArray(cleanedEntry.entity)
          ? cleanedEntry["entityvalue"][0]
          : cleanedEntry["entityvalue"];
        provider = cleanedEntry["provider"]
          ? cleanedEntry["provider"]
          : Array.isArray(cleanedEntry.streamprovider)
          ? cleanedEntry["streamprovider"][0]
          : cleanedEntry["streamprovider"];

        Object.assign(cleanedEntry, entry.artifacts);
        fieldsToDrop.forEach((field) => {
          delete cleanedEntry[field];
        });

        cleanedResponse[timestamp] = cleanedEntry;
      });

      Flow.setentity("" + entity);
      Flow.setentitytype(entitytype);
      Flow.setdeetctiondata(args);
      Flow.setprovider(provider);
      const timestamps = Object.keys(cleanedResponse).map((timestamp) =>
        parseInt(timestamp)
      );
      setGlobalState("selectedTimestamp", timestamps[0]);
      Flow.detectiongraphnodesedges(cleanedResponse, incidentrequest,requestdata);
    }
  },
  enitydetails: (rows, output) => {
    const request = {
      operation: "get_hr_data",
      object_value: "" + rows?.entity,
      object_label: rows?.entitytype,
      node_types: ["entity"],
      level: 1,
    };

    ajax(
      "post",
      requestURI["dashboard.entity.garph.query"],
      null,
      request,
      PanelScript.renderentitygraphquery,
      showError,
      [output, rows]
    );
  },
  renderentitygraphquery: (result, args) => {
    let hrData = null;

    if (
      result &&
      result.data &&
      result.data.response &&
      result.data.response.length > 0
    ) {
      hrData = result.data.response[0].node;
    }
    const employmentstatusData = hrData?.find(
      (item) => item.label === "employmentstatus"
    );
    const employmentid = hrData?.find((item) => item.label === "employeeid");
    const employmedepartment = hrData?.find(
      (item) => item.label === "department"
    );
    const employmendesignation = hrData?.find(
      (item) => item.label === "designation"
    );
    const employmencountry = hrData?.find((item) => item.label === "country");

    // const statusdata = (
    //   <p className="me-3">
    //   {" "}
    //   <span
    //     className="badge badge badge-success-lighten p-1 px-3"
    //   >
    //     {employmentstatusData}
    //   </span>
    // </p>
    // );
    //     const statuscontainer = document.querySelector("#bnfxenitystatus");

    //     CommonTemplate.ContainerAvailability(statuscontainer, statusdata);
    // console.log(employmentstatusData)

    const filteredHrData = hrData?.filter(
      (attr) =>
        attr.label !== "ManagerId" &&
        attr.label !== "date-of-birth" &&
        attr.label !== "id" &&
        attr.label !== "firstName" &&
        attr.label !== "lastName" &&
        attr.label !== "contact" &&
        attr.label !== "Date-of-Joining" &&
        attr.label !== "status" &&
        attr.label !== "position" &&
        attr.label !== "employmentstatus"
    );
    // const formattedKeyValuePairs = filteredHrData?.map((attr) => {
    //   const capitalizedLabel = attr.label.replace(/\b\w/g, (char) =>
    //     char.toUpperCase()
    //   );
    //   return (
    //     <>
    //       <span className="bxEventKey">{capitalizedLabel}:</span>
    //       <span className="bxEventValue">{attr.value}</span>
    //     </>
    //   );
    // });

    //let details = (({ score, max, criticality, ...rest }) => rest)(output);
    const displaycontainer = document.querySelector("#bnfxActionList");
    displaycontainer.setAttribute("data-current-container", "dashboard");

    let time = moment(new Date(args[0].scoretimestamp)).format("DD  MMM hh:mm");
    const displaydata = (
      <EntityDisplayPanel
        total={args[0].score}
        criticality={args[0].criticality}
        logo={"person"}
        name={"" + args[1].entity}
        timestamp={time}
        tab1={"detections"}
        tab2={"timeline"}
        row={args[1]}
        onClickFn={PanelScript.OutlierTabRenderFunction}
        hrdetails={[
          employmentstatusData,
          employmentid,
          employmedepartment,
          employmendesignation,
          employmencountry,
        ]}
        filteredData={filteredHrData}
        callback={() => PanelScript.loadentitytrendgraph(args[1])}
      />
    );
    CommonTemplate.ContainerAvailability(displaycontainer, displaydata);
  },
  graphrender: (title, id, entity, entitytype, tenant, score, streamid) => {
    PanelScript.handleGraphTabClick(
      title,
      id,
      entity,
      entitytype,
      tenant,
      score,
      streamid
    );
    setGlobalState("ispanelrequired", true);
  },
  handleGraphTabClick: (
    title,
    id,
    entity,
    entitytype,
    tenant,
    score,
    streamid
  ) => {
    let incidenceclass = document.getElementById("bxOffcanvasIncidence");
    incidenceclass.classList.add("graphopen");
    setGlobalState("globaldetectiongraphid", [id, title, score]);
    PanelScript.loaddetectiongraph(
      id,
      entity,
      entitytype,
      title,
      tenant,
      score,
      streamid
    );
  },
  bxOffcanvasHeights: () => {
    let panelheight =
      document?.querySelector("#bxOffcanvasPanel")?.clientHeight;
    let headerheight = document?.querySelector("#panelheader")?.clientHeight;
    let tabsection = document?.querySelector("#myTab")?.clientHeight;
    let finalheight = panelheight - headerheight - tabsection - 40 - 122 - 40;
    return finalheight;
  },

  convertrequireddteectiondata: (data, clicktype, streamid) => {
    var removeCollapse = document.getElementById("bxOffcanvasPanelLayout");

    removeCollapse.classList.remove("d-none");
    removeCollapse.classList.add("d-block");

    let detectiondata = null;
    if (clicktype === "entity") {
      detectiondata = {
        entity: "" + data.entity,
        entitytype: data.entitytype,
        risk_score: data.score,
        type: "entity",
        streamid: streamid,
        tenant: data.tenant,
      };
    } else {
      detectiondata = {
        detectionid: data.detectionid,
        name: data.name ? data.name : "no title",
        type: "detection",
        streamid: streamid,
        tenant: data.tenant,
      };
    }
    let starttime = PanelScript.default_startDate;
    let endtime = PanelScript.default_endDate;
    PanelScript.loadrowOnclick(
      detectiondata,
      starttime,
      endtime,
      PanelScript.default_timePhrase
    );
  },
  loadlist: (row) => {
    PanelScript.loadEntityMeta(row);
    PanelScript.loadDetectionMeta(row);
  },
  loaddetectiontrendgraph: (data) => {
    CommonTemplate.renderLoadingWaveIcon("#detectiontrendgraph")
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      detectionid: data.detectionid,
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };
    ajax(
      "post",
      requestURI["detection.trend.chart"],
      null,
      request,
      PanelScript.renderdetectiontrendgraph,
      showError
    );
  },
  renderdetectiontrendgraph: (result) => {
    let output = result.data.response.result;
    let series = output.data;
    let categories = output.categories;

    const container = document.querySelector("#detectiontrendgraph");

    let trendchart = (
      <TrendChart
        series={series}
        categories={categories}
        container={"detectiontrendgraph"}
        height={110}
        tickInterval={categories.length > 10 ? 6 : 3}
      />
    );
    CommonTemplate.ContainerAvailability(container, trendchart);
  },
  loadentitytrendgraph: (data) => {
    CommonTemplate.renderLoadingWaveIcon("#entitytrendgraph")
    const request = {
      starttime: PanelScript.default_startDate,
      endtime: PanelScript.default_endDate,
      entity: "" + data.entity,
      entitytype: data.entitytype,
      tenant: data.tenant,
      timePhrase: PanelScript.default_timePhrase,
    };
    ajax(
      "post",
      requestURI["entity.trend.chart"],
      null,
      request,
      PanelScript.renderentitytrendgraph,
      showError
    );
  },
  renderentitytrendgraph: (result) => {
    let output = result.data.response.result;
    let series = output.data;
    let categories = output.categories;

    const container = document.querySelector("#entitytrendgraph");

    let trendchart = (
      <TrendChart
        series={series}
        categories={categories}
        container={"entitytrendgraph"}
        height={120}
        tickInterval={categories.length > 10 ? 6 : 3}
      />
    );
    CommonTemplate.ContainerAvailability(container, trendchart);
  },
  loadleftpaneltrendgraph: () => {
    let dictionaryname={}
    if (PanelScript.selected_row && PanelScript.selected_row.data && Array.isArray(PanelScript.selected_row.data.uniquekey)) {
      for (let i = 0; i < PanelScript.selected_row.data.uniquekey.length; i++) {
          let key = PanelScript.selected_row.data.uniquekey[i]; 

          if (PanelScript.selected_row.point) {
            dictionaryname[key] = PanelScript.selected_row.point[key];
          }else if(PanelScript.selected_row.rows){
            dictionaryname[key] = PanelScript.selected_row.rows[key]; 
          }
      }
  } else {
      console.error('PanelScript.selected_row.data.uniquekey is not defined or not an array');
  }
    CommonTemplate.renderLoadingWaveIcon("#bnfxtrendgraph")
    

    let request =null;
    if(PanelScript.selected_row && PanelScript.selected_row.data && PanelScript.selected_row.data.uniquekey){
      request = {
        starttime: PanelScript.default_startDate,
        endtime: PanelScript.default_endDate,
        value: PanelScript.selected_row.name,
        column: PanelScript.selected_row.column,
        timePhrase: PanelScript.default_timePhrase,
        keymap:dictionaryname,
        uniquekey:PanelScript.selected_row.data.uniquekey
      };
  
    }else{
      request = {
        starttime: PanelScript.default_startDate,
        endtime: PanelScript.default_endDate,
        value: PanelScript.selected_row.name,
        column: PanelScript.selected_row.column,
        timePhrase: PanelScript.default_timePhrase,
        
      };
  
    }
    ajax(
      "post",
      requestURI["entity.trend.chart"],
      null,
      request,
      PanelScript.rendertrendgraph,
      showError
    );
  },
  rendertrendgraph: (response) => {
    let series = response.data.response.result.data;
    let categories = response.data.response.result.categories;

    const container = document.querySelector("#bnfxtrendgraph");

    let trendchart = (
      <TrendChart
        series={series}
        categories={categories}
        container={"bnfxtrendgraph"}
        height={120}
        tickInterval={categories.length > 10 ? 6 : 3}
      />
    );
    CommonTemplate.ContainerAvailability(container, trendchart);
  },
  resolveDetection: (output) => {
    let data = output[0];

    PanelScript.settimeline_data(data);
    const request = {
      detection: data,
    };
    ajax(
      "post",
      requestURI["dashboard.detection.resolve"],
      null,
      request,
      PanelScript.rendertResolveData,
      showError
    );
  },
  rendertResolveData: () => {
    let entity = PanelScript.gettimeline_data.entity;
    let detectionname = PanelScript.gettimeline_data.name;

    ToastPopup("The detection " + detectionname + " is resolved by " + entity);
  },
  handleClick: (output) => {
    let criticality = output[0]?.criticality;
    if (criticality === "NONE") {
      const container = document.querySelector(
        ".bageDetection.d-flex.align-items-center.resloveButton"
      );
      if (container) {
        container.classList.add("d-none");
      }
    }
  },
};
