import React from 'react';

const FatalErrorWidget = (props) => {
    let rowData=props.data;
  
   
  return (
    
<div className="fatalErrorLayout">
    <div className="bxTabelRelations">
      <table className="table table-centered mb-0">
        <thead className="table-dark">
          <tr className="fatal_header">
            <th className="fatal-column">Datetime</th>
            <th className="fatal-column">Severity</th>
            <th className="fatal-column">Database</th>
            <th className="fatal-column">User</th>
            <th className="fatal-column">Msg</th>
            <th className="fatal-column">Count</th>
          </tr>
        </thead>
        <tbody>
          {rowData?.map((row, index) => (
          <tr className="fatal-row" key={index}>
              <td className="fatal-column">{row.timestamp}</td>
              <td className="fatal-column">{row.event_severity}</td>
              <td className="fatal-column">{row.dbname}</td>
              <td className="fatal-column">{row.user_name}</td>
              <td className="fatal-column">{row.message}</td>
              <td className="fatal-column">{row.event_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default FatalErrorWidget;
