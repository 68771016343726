import React, { useEffect } from "react";
import RawEventLayout from "./RawEventLayout";

function RawEvent(props) {
  let data = null;

  useEffect(() => {
    if (props.callback) {
      props.callback();
    }
  });

  if (props.output) {
    data = props.output.map((streamObj, index) => (
      <React.Fragment key={index}>
        <RawEventLayout data={streamObj} index={index} />
      </React.Fragment>
    ));
  }

  return <div className="bnfxDectionsEvents mt-0 bnfxrawevents">{data}</div>;
}

export default RawEvent;
