import $ from "jquery";
import React from "react";
import { createRoot } from "react-dom/client";
import Select from "react-select";
import Swal from "sweetalert2";
import { showError } from "../../components/common/DisplayError";
import {
  getGlobalState,
  setGlobalState,
} from "../../components/common/GlobalVariables";
import NoContent from "../../components/common/NoContent";
import { requestURI } from "../../components/common/RequestURIs";
import { ajax } from "../../components/common/Service";
import Pagination from "../../components/common/pagination/Pagination";
import DashboardWrapperContainer from "./DashboardWrapperContainer";
import { t } from "i18next";
import moment from "moment";
import ToastPopup from "../../components/common/ToastPopup";
import DashboardLayout from "./details/DashboardLayout";
import DashboardMetadata from "./details/DashboardMetadata";
import NoCardData from "./details/NoCardData";
import NoDashboardAvailable from "./details/NoDashboardAvailable";
import NoWidgetData from "./details/NoWidgetData";
import WidgetDataLayout from "./details/WidgetDataLayout";
import WidgetLayout from "./details/WidgetLayout";
import DashboardListData from "./list/DashboardListData";
import { CommonTemplate } from "../../components/common/containerAvailability/CommonScript";
import { PanelScript } from "../../components/common/GridPanel.js/PanelScript";
import DashLeftSidePanel from "../../components/common/GridPanel.js/DashLeftSidePanel";
import NoStatCard from "../stream/details/NoStatCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const Dashboard = {
  searchtimeout: null,
  widgetfilters: {},
  selected_dashboard: null,
  available_dashboard: null,
  enabled_dashboards_total: null,
  available_dashboards_total: null,
  grid: null,
  startDate: null,
  endDate: null,
  default_startDate: null,
  default_endDate: null,
  select_widgetid: null,
  widgetObjectMap: {},
  default_newstartDate: null,
  default_newendDate: null,
  widget_param: null,

  clearDashboardlayout: () => {
    Dashboard.searchtimeout = null;
    Dashboard.widgetfilters = {};
    Dashboard.selected_dashboard = null;
    Dashboard.available_dashboard = null;
    Dashboard.enabled_dashboards_total = null;
    Dashboard.available_dashboards_total = null;
    Dashboard.grid = null;
    Dashboard.startDate = null;
    Dashboard.endDate = null;
    Dashboard.default_startDate = null;
    Dashboard.default_endDate = null;
    Dashboard.select_widgetid = null;
    Dashboard.widgetObjectMap = {};
    Dashboard.probefrom = null;
    Dashboard.widget_param = null;
  },

  init: () => {
    Dashboard.adjustTabContentHeight();
  },

  loadflitersoptions: (widgetid) => {
    const widgetMap = getGlobalState("GlobalWidgetId");
    Dashboard.loadFilters(widgetid, widgetMap[widgetid]);
  },

  setWidgetParam: (widget_param) => {
    Dashboard.widget_param = widget_param;
  },

  setSelectWidget: (select_widgetid) => {
    Dashboard.select_widgetid = select_widgetid;
  },

  getPaginationWidgets: (offset, widget) => {
    Dashboard.renderWidgetData(widget, offset, true);
  },

  setDefaultNewStartDate: (default_newstartDate) => {
    Dashboard.default_newstartDate = default_newstartDate;
  },
  setDefaultNewEndDate: (default_newendDate) => {
    Dashboard.default_newendDate = default_newendDate;
  },

  setDefaultStartDate: (default_startDate) => {
    Dashboard.default_startDate = default_startDate;
  },

  setDefaultEndDate: (default_endDate) => {
    Dashboard.default_endDate = default_endDate;
  },
  setStartDate: (startDate) => {
    Dashboard.startDate = startDate;
  },

  setEndDate: (endDate) => {
    Dashboard.endDate = endDate;
  },

  selectDashboard: (dashboard) => {
    Dashboard.selected_dashboard = dashboard;
  },

  selectAvailableDashboard: (dashboard) => {
    Dashboard.available_dashboard = dashboard;
  },

  loadEnabledDashboardLayout: (offset) => {
    Dashboard.listEnabledDashboards(offset);
    Dashboard.getCount(true, "enabled_dashboard_search");
  },

  loadAvailableDashboardLayout: (offset) => {
    Dashboard.listAvailableDashboards(offset);
    Dashboard.getCount(false, "available_dashboard_search");
  },

  loadDashboardDetailsLayout: (dashboard) => {
    Dashboard.renderDashboardMetaData(dashboard);
  },

  listEnabledDashboards: (offset) => {
    let freetext = Dashboard.getSearchText("enabled_dashboard_search");

    const request = {
      limit: Math.floor(
        Dashboard.adjustEnabledTabContentHeight() /
          (window.innerWidth > 1500 ? 90 : 71)
      ),
      offset: offset ? offset : 0,
      query: freetext ? freetext : undefined,
      enabled: true,
      sortorder: false,
      sortcol: "lastupdated",
      type: "packages.dashboards",
    };

    ajax(
      "post",
      requestURI["dashboard.list"],
      null,
      request,
      Dashboard.renderEnabledDashboards,
      showError,
      [freetext]
    );
  },

  renderEnabledDashboards: (result, args) => {
    const dashboards = result.data.response;
    const freetext = args[0];

    if (dashboards.length === 0) {
      if (freetext === null) {
        const container = document.querySelector(".tab-content");
        const data = <NoDashboardAvailable />;
        CommonTemplate.ContainerAvailability(container, data);
      } else {
        const container = document.querySelector(".bxDList_box");
        const data = (
          <NoContent
            id={"noenabledashboard"}
            title={t("title.nodashboardfound")}
            description={t("description.nodashboardfound")}
          />
        );
        CommonTemplate.ContainerAvailability(container, data);
        Dashboard.clearLayout();
      }
    } else {
      const container = document.querySelector("#bnfxEnabledDashboardList");
      const data = (
        <DashboardListData
          dashboards={dashboards}
          enabled
          callback={
            !freetext
              ? () => {
                  const firstdashboard =
                    document.querySelector(".dashboard-link");
                  if (firstdashboard) {
                    firstdashboard.click();
                  }
                }
              : null
          }
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  listAvailableDashboards: (offset) => {
    let freetext = Dashboard.getSearchText("available_dashboard_search");

    const request = {
      limit: Math.floor(
        Dashboard.adjustAvailableTabContentHeight() /
          (window.innerWidth > 1500 ? 90 : 71)
      ),
      offset: offset ? offset : 0,
      query: freetext ? freetext : undefined,
      enabled: false,
      sortorder: false,
      sortcol: "name",
      type: "packages.dashboards",
    };

    ajax(
      "post",
      requestURI["dashboard.list"],
      null,
      request,
      Dashboard.renderAvailableDashboards,
      showError
    );
  },

  renderAvailableDashboards: (result) => {
    const dashboards = result.data.response;
    const enabled = false;

    if (dashboards.length === 0) {
      const container = document.querySelector("#bnfxAvailableDashboardList");
      const data = (
        <NoContent
          id={"noavailabledashboard"}
          title={t("title.nodashboardfound")}
          description={t("description.nodashboardfound")}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const container = document.querySelector("#bnfxAvailableDashboardList");
      const data = (
        <DashboardListData dashboards={dashboards} enabled={enabled} />
      );
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  getCount: (enabled, id) => {
    let freetext = Dashboard.getSearchText(id);

    const parameters = {
      query: freetext ? freetext : undefined,
      enabled: enabled,
      type: "packages.dashboards",
    };

    enabled
      ? ajax(
          "post",
          requestURI["dashboard.count"],
          null,
          parameters,
          Dashboard.enabledDashboardPagination,
          showError
        )
      : ajax(
          "post",
          requestURI["dashboard.count"],
          null,
          parameters,
          Dashboard.availableDashboardPagination,
          showError
        );
  },

  enabledDashboardPagination: (result) => {
    const limit = Math.floor(
      Dashboard.adjustEnabledTabContentHeight() /
        (window.innerWidth > 1500 ? 90 : 71)
    );

    const total = result.data.response;
    Dashboard.enabled_dashboards_total = total;

    Dashboard.createPagination(
      total,
      limit,
      "bnfxEnabledDashboardPagination",
      true
    );
  },

  availableDashboardPagination: (result) => {
    let limit = Math.floor(
      Dashboard.adjustAvailableTabContentHeight() /
        (window.innerWidth > 1500 ? 90 : 71)
    );
    let total = result.data.response;
    Dashboard.available_dashboards_total = total;
    Dashboard.createPagination(
      total,
      limit,
      "bnfxAvailableDashboardPagination",
      false
    );
  },

  createPagination: (total, limit, id, enabled) => {
    const container = document.querySelector("#" + id);

    if (!container) {
      return;
    }

    const paginationData = {
      total: total,
      limit: limit,
      offset: 0,
    };

    const paginationComponent = enabled ? (
      <Pagination
        pagination={paginationData}
        onClickFn={Dashboard.listEnabledDashboards}
      />
    ) : (
      <Pagination
        pagination={paginationData}
        onClickFn={Dashboard.listAvailableDashboards}
      />
    );

    if (total > limit) {
      CommonTemplate.ContainerAvailability(container, paginationComponent);
    } else {
      const paginationComponent = null;
      CommonTemplate.ContainerAvailability(container, paginationComponent);
    }
    enabled
      ? Dashboard.setTotal(total, ".bnfxEnabledDashboardTotal")
      : Dashboard.setTotal(total, "#bnfxAvailableDashboardTotal");
  },

  setTotal: (total, id) => {
    const container = document.querySelectorAll(id);
    container.forEach((content) => {
      content.innerText = total;
    });
  },

  handleDashboardToggleCollapse: () => {
    const collapsed = getGlobalState("global_left_panel_collapsed");
    setGlobalState("global_left_panel_collapsed", !collapsed);
  },

  loadFilters: (widgetid, filters) => {
    document.getElementById("bnfxfilterPopupLoadingIcon").style.display =
      "block";
    const filterselectdropdown = document.getElementById(
      "filterselectdropdown"
    );

    if (!filterselectdropdown) {
      return;
    }

    filterselectdropdown.innerHTML = "";

    const bnfxFilters = document.querySelectorAll(
      ".bxPopup[widgetid = '" + widgetid + "'] .filterBody .bnfxFilters"
    );

    if (bnfxFilters && bnfxFilters.length > 0) {
      return;
    }

    for (let index = 0; index < filters.length; index++) {
      const filterid = filters[index];
      const request = {
        identifier: filterid,
        offset: 0,
      };

      ajax(
        "post",
        requestURI["dashboard.filter"],
        null,
        request,
        Dashboard.renderFilter,
        showError,
        [widgetid, filterid]
      );
    }
  },

  setFilter: (selected, widgetid, filterid) => {
    const values = [];

    if (selected[0]) {
      for (let index = 0; index < selected.length; index++) {
        const selectedval = selected[index];
        values.push(selectedval["value"]);
      }

      if (!Dashboard.widgetfilters[widgetid]) {
        Dashboard.widgetfilters[widgetid] = {};
      }

      Dashboard.widgetfilters[widgetid][filterid] = values;
      Dashboard.renderWidgetData(Dashboard.widgetObjectMap[widgetid], 0, false);
    } else {
      delete Dashboard.widgetfilters[widgetid][filterid];
      Dashboard.renderWidgetData(Dashboard.widgetObjectMap[widgetid], 0, false);
    }

    let filtercount = 0;
    const widgetfilters = Dashboard.widgetfilters[widgetid];

    for (let widgetfilterkey in widgetfilters) {
      filtercount += widgetfilters[widgetfilterkey].length;
    }

    const countelement = document.getElementById("bnfxFSCount-" + widgetid);
    localStorage.setItem(`filtercount${widgetid}`, JSON.parse(filtercount));

    if (filtercount > 0) {
      countelement.innerText = filtercount;
      countelement.classList.remove("d-none");
    } else {
      countelement.innerText = filtercount;
      countelement.classList.add("d-none");
    }
  },

  renderFilter: (result, args) => {
    let filters = result.data.response.values.result;

    if (
      Array.isArray(filters) &&
      filters.length > 0 &&
      typeof filters[0] === "object"
    ) {
      document.getElementById("bnfxfilterPopupLoadingIcon").style.display =
        "none";
      const filtercontainer = document.querySelector("#filterselectdropdown");
      const appender = document.createElement("div");
      filtercontainer.appendChild(appender);

      const root = createRoot(appender);
      const filteroptions = filters.map((item, index) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];

        return {
          widgetid: args[0],
          filterid: key,
          value: value,
          label: value,
        };
      });

      let defaultValue = null;
      if (Dashboard.widgetfilters[filteroptions[0].widgetid]) {
        defaultValue =
          Dashboard.widgetfilters[filteroptions[0].widgetid][
            filteroptions[0].filterid
          ];
        const dropdownvalues = [];
        if (defaultValue) {
          for (let index = 0; index < defaultValue.length; index++) {
            const value = defaultValue[index];
            dropdownvalues.push({ value: value, label: value });
          }
          defaultValue = dropdownvalues;
        }
      }

      root.render(
        <>
          <span className="d-block mb-2 mt-2 text-uppercase">
            {filteroptions[0].filterid}
          </span>
          <div className="col-12">
            <Select
              id="filters"
              classNameName="form-control"
              isMulti
              options={filteroptions}
              onChange={(selected) =>
                Dashboard.setFilter(
                  selected,
                  filteroptions[0].widgetid,
                  filteroptions[0].filterid
                )
              }
              defaultValue={defaultValue}
            ></Select>
          </div>
        </>
      );
    }
  },

  search: (id, widget) => {
    const freetext = document.getElementById(id)?.value;

    if (freetext?.length >= 3) {
      if (Dashboard.searchtimeout && Dashboard.searchtimeout.length > 0) {
        for (let index = 0; index < Dashboard.searchtimeout.length; index++) {
          window.clearTimeout(Dashboard.searchtimeout[index]);
        }
      }

      Dashboard.searchtimeout = window.setTimeout(() => {
        if (id === "enabled_dashboard_search") {
          Dashboard.loadEnabledDashboardLayout(0);
        } else if (id === "available_dashboard_search") {
          Dashboard.loadAvailableDashboardLayout(0);
        } else if (id === "bnfxtablelistsearch" + widget.identifier) {
          Dashboard.renderWidgetData(widget, 0, false);
        }
      }, 3000);
    }
  },

  getSearchText: (id) => {
    let freetext = null;
    if (document.getElementById(id)) {
      freetext = document.getElementById(id).value;

      if (freetext.trim() === "" || freetext.length < 3) {
        freetext = null;
      }
    }

    return freetext;
  },

  loadDashboard: (dashboard, offset) => {
    Dashboard.clearDashboardlayout();
    Dashboard.selectDashboard(dashboard);

    if (offset === undefined) {
      offset = 0;
    }

    const container = document.querySelector("#bnfxDashboardLayout");

    const data = <DashboardLayout dashboard={dashboard} />;
    CommonTemplate.ContainerAvailability(container, data);

    const request = {
      identifier: dashboard._id,
    };

    ajax(
      "post",
      requestURI["dashboard.details"],
      null,
      request,
      Dashboard.renderDashboard,
      showError,
      [offset]
    );
  },

  renderDashboard: (result, args) => {
    var widgets = result.data.response["packages.widgets"];

    let offset = args[0];

    if (widgets) {
      for (let index = 0; index < widgets.length; index++) {
        setTimeout(() => {
          var widgetid = widgets[index];
          Dashboard.loadWidgetLayout(widgetid, index, offset);
        }, index * 100);
      }
    }
  },

  renderDashboardMetaData: (dashboard) => {
    const container = document.querySelector("#bnfxDashboardMetaData");

    const data = <DashboardMetadata dashboard={dashboard} />;
    CommonTemplate.ContainerAvailability(container, data);
  },

  loadWidgetLayout: (identifier, index, offset) => {
    Dashboard.setSelectWidget(identifier);

    const request = {
      identifier: identifier,
    };

    ajax(
      "post",
      requestURI["dashboard.widgetdetails"],
      null,
      request,
      Dashboard.renderWidgetLayout,
      showError,
      [index, offset]
    );
  },

  renderWidgetLayout: (result, args) => {
    var filters = result.data.response.configure.filters;
    var widget = result.data.response;
    var widgetid = result.data.response.identifier;
    var filtervisible = true;

    var widgetMap = getGlobalState("GlobalWidgetId");
    widgetMap[widgetid] = [];

    if (filters) {
      for (let index = 0; index < filters.length; index++) {
        widgetMap[widgetid].push(filters[index]);
      }
    } else {
      filtervisible = false;
    }

    setGlobalState("GlobalWidgetId", widgetMap);

    const container = document.querySelector("#bxDashboardWiget");
    const appender = document.createElement("div");
    appender.classList.add("d-none");
    container.appendChild(appender);
    const root = createRoot(appender);
    let offset = args[1];
    widget.filtervisible = filtervisible;
    Dashboard.widgetObjectMap[widgetid] = widget;
    root.render(
      <WidgetLayout
        widget={widget}
        index={args[0]}
        dashboardid={Dashboard.selected_dashboard._id}
        callback={() => Dashboard.renderWidgetData(widget, offset, false)}
      />
    );
  },

  reRenderWidgets: (selecteddate) => {
    const widgets = Dashboard.widgetObjectMap;
    let i = 0;
    for (var widgetid in widgets) {
      localStorage.setItem(
        `date_${Dashboard.selected_dashboard._id}${
          "bnfxWidgetDatePicker-" + widgetid
        }`,
        selecteddate
      );
      Dashboard.loadWidgetLayout(widgetid, i++, 0);
    }
  },

  renderDatePicker: (current) => {

    let id = current.getAttribute("id");

    let comparetimestartdate = null;

    const datepicker = $(current).data("daterangepicker");

    if (datepicker) {
      let defaultStartDate = datepicker.startDate.format("YYYY-MM-DD hh:mm A");
      let defaultEndDate = datepicker.endDate.format("YYYY-MM-DD hh:mm A");
      comparetimestartdate = CommonTemplate.timeconversion(
        moment().startOf("hour").subtract(1, "days")
      );
      if (defaultStartDate === comparetimestartdate) {
        Dashboard.setDefaultStartDate(defaultStartDate);
        Dashboard.setDefaultEndDate(defaultEndDate);
      } else {
        Dashboard.setDefaultNewStartDate(defaultStartDate);
        Dashboard.setDefaultNewEndDate(defaultEndDate);
      }

      $(current).on("apply.daterangepicker", function (ev, picker) {
        const startDate = picker.startDate.format("YYYY-MM-DD hh:mm A");
        const endDate = picker.endDate.format("YYYY-MM-DD hh:mm A");

        localStorage.setItem("defaultStartDate", startDate);
        localStorage.setItem("defaultEndDate", endDate);
        let dashboardid = Dashboard.selected_dashboard?._id;
        if (picker.chosenLabel) {
          if (picker.chosenLabel === "Custom Range") {
            localStorage.removeItem(`date_${dashboardid}${id}`);

            let valueToStore = {
              customdate: { startdate: startDate, enddate: endDate },
            };
            localStorage.setItem(
              `date_${dashboardid}${id}`,
              JSON.stringify(valueToStore)
            );
          } else {
            localStorage.removeItem(`date_${dashboardid}${id}`);

            let valueToStore = { range: picker.chosenLabel };
            localStorage.setItem(
              `date_${dashboardid}${id}`,
              JSON.stringify(valueToStore)
            );
          }
        } else {
          localStorage.removeItem(`date_${dashboardid}${id}`);
          let valueToStore = {
            defaultEndDate: defaultEndDate,
            defaultStartDate: defaultStartDate,
          };
          localStorage.setItem(
            `date_${dashboardid}${id}`,
            JSON.stringify(valueToStore)
          );
        }
        Dashboard.setStartDate(startDate);
        Dashboard.setEndDate(endDate);

        if (id === "dashboardDatePicker") {
          const selecteddate = localStorage.getItem(`date_${dashboardid}${id}`);
          Dashboard.reRenderWidgets(selecteddate);
        } else {
          const widgetid = id.replace("bnfxWidgetDatePicker-", "");
          Dashboard.renderWidgetData(
            Dashboard.widgetObjectMap[widgetid],
            0,
            false
          );
        }
      });
    }
  },

  renderWidgetData: (widget, offset, fromPagination) => {
    CommonTemplate.renderLoadingIcon(
      ".bxDashboardWidget[widgetid = '" + widget.identifier + "']"
    );
    let freetext = Dashboard.getSearchText(
      "bnfxtablelistsearch" + widget?.identifier
    );

    const container = document.getElementById(
      "grid-stack-item-" + widget?.identifier
    );
    if (container) {
      let limit = -1;

      let height = null;

      if (
        widget.configure.properties.type === "table" ||
        widget.configure.properties.type === "my_queue"
      ) {
        let widgetheight = container.offsetHeight;

        if (widget.configure.searchable === true) {
          const searchheight =
            document.querySelector(".input-group").clientHeight;
          let dataheight = widgetheight - searchheight;
          height = dataheight / (widgetheight > 800 ? 80 : 120);
        } else {
          height = widgetheight / 80;
        }

        limit = Math.ceil(height);
      }
      if (
        (widget.configure.properties.limit !== undefined) &
        (widget.configure.properties.limit !== null)
      ) {
        limit = widget.configure.properties.limit;
      }

      let dashboardid = Dashboard.selected_dashboard?._id;
      let data = localStorage.getItem(
        `date_${dashboardid}bnfxWidgetDatePicker-${widget.identifier}`
      );
      let parsedValue = JSON.parse(data)?.range;
      let request = {
        identifier: widget.identifier,
        limit: limit,
        freetext: freetext ? freetext : undefined,
        offset: offset ? offset : 0,
        starttime:
          Dashboard.startDate !== null
            ? Dashboard.startDate
            : Dashboard.default_newstartDate !== null
            ? Dashboard.default_newstartDate
            : Dashboard.default_startDate,

        endtime:
          Dashboard.endDate !== null
            ? Dashboard.endDate
            : Dashboard.default_newendDate !== null
            ? Dashboard.default_newendDate
            : Dashboard.default_endDate,

        timezone: CommonTemplate.Timezone,
        timePhrase: parsedValue,
      };

      let filterparams = Dashboard.widgetfilters[widget.identifier];
      if (filterparams) {
        localStorage.setItem(
          `widgetfilter_id${widget?.identifier}`,
          JSON.stringify(filterparams)
        );
      }

      let filterdata = localStorage.getItem(
        `widgetfilter_id${widget?.identifier}`
      );
      let countfilter = localStorage.getItem(
        `filtercount${widget?.identifier}`
      );

      if (filterdata && filterdata.length > 0) {
        try {
          let data = JSON.parse(filterdata);
          for (let key in data) {
            Dashboard.setDefaultFilter(widget, data, countfilter);
            request["filters"] = JSON.parse(filterdata);
          }
        } catch (error) {
          //console.log(error)
        }
      } else {
        request["filters"] = filterparams;
      }

      ajax(
        "post",
        requestURI["dashboard.widgetdata"],
        null,
        request,
        Dashboard.renderData,
        Dashboard.displayError,
        [widget, offset, fromPagination, limit]
      );
    }
  },

  displayError(error, args) {
    if (args && args.length > 0 && args[0] && args[0].identifier) {
      const widgetid = args[0].identifier;
      const container = document.getElementById(
        "bnfxWidgetContainer-" + widgetid
      );

      let nodata = null;
      if (container) {
        if (!container.closest(".bxDashboardCard")) {
          let removePagintion = document.getElementById(
            "bnfxTablePagination" + args[0].identifier
          );
          if (removePagintion) {
            removePagintion.classList.add("d-none");
          }
          nodata = <NoWidgetData id={"bnfxWidgetNoData-" + widgetid} />;
        } else {
          nodata = <NoCardData id={"bnfxWidgetNoDataCard-" + widgetid} />;
        }

        CommonTemplate.ContainerAvailability(container, nodata);

        if (document.getElementById("bnfxWidgetNoData-" + widgetid)) {
          let message =
            error.response == null ? error : error.response.data.error;
          document.getElementById("bnfxWidgetNoData-" + widgetid).innerText =
            message;
        }
      } else {
        console.error("Container not found for widget with ID: " + widgetid);
      }
    } else {
      console.error("Invalid args or args[0].identifier is undefined");
    }
  },

  renderData: (result, args) => {
    const total = result.data?.response?.total;
    const widget = args[0];
    const offset = args[1];
    const fromPagination = args[2];
    const limit = args[3];
    const status = result.data?.response?.status;
    const pagination = widget.configure.pagination;

    if (status === "failed") {
      const container = document.getElementById(
        "bnfxWidgetContainer-" + widget.identifier
      );
      const nodata = (
        <NoWidgetData id={"bnfxWidgetNoData-" + widget.identifier} />
      );
      CommonTemplate.ContainerAvailability(container, nodata);

      const nostat = document.getElementById(
        "bnfxWidgetStatCard" + widget.identifier
      );

      const statdata = <NoStatCard id={widget.identifier} />;
      CommonTemplate.ContainerAvailability(nostat, statdata);

      let removePagintion = document.getElementById(
        "bnfxTablePagination" + widget.identifier
      );
      if (removePagintion) {
        removePagintion.classList.add("d-none");
      }
    } else {
      const container = document.querySelector(
        ".bxDashboardWidget[widgetid = '" + widget.identifier + "']"
      );

      let showPagintion = document.getElementById(
        "bnfxTablePagination" + widget.identifier
      );
      if (showPagintion) {
        showPagintion.classList.remove("d-none");
      }
      let data = null;

      if (fromPagination) {
        data = <WidgetDataLayout widget={widget} data={result} />;
      } else {
        data = (
          <WidgetDataLayout
            widget={widget}
            data={result}
            callback={() => {
              if (pagination !== false) {
                Dashboard.renderPagination(total, widget, offset, limit);
              }
            }}
          />
        );
      }
      CommonTemplate.ContainerAvailability(container, data);
    }
  },

  renderPagination: (total, widget, offset, limit) => {
    if (total > limit) {
      const paginationData = {
        total: total,
        limit: limit,
        offset: offset ? offset : 0,
      };

      const container = document.getElementById(
        "bnfxTablePagination" + widget.identifier
      );

      const data = (
        <Pagination
          pagination={paginationData}
          fnArg={widget}
          onClickFn={Dashboard.getPaginationWidgets}
        />
      );
      CommonTemplate.ContainerAvailability(container, data);
    } else {
      const paginationcontainer = document.getElementById(
        "bnfxTablePagination" + widget.identifier
      );

      const paginationdata = null;
      CommonTemplate.ContainerAvailability(paginationcontainer, paginationdata);
    }
  },

  extractDeleteDashboardData: () => {
    return {
      tenant: Dashboard.selected_dashboard?.tenant,
      id: Dashboard.selected_dashboard?._id,
      type: "dashboards",
    };
  },

  deleteDashboard: () => {
    const payload = Dashboard.extractDeleteDashboardData();
    const withLayout = Dashboard.enabled_dashboards_total === 1 ? true : false;

    ajax(
      "post",
      requestURI["disable.content"],
      null,
      payload,
      Dashboard.streamdeletedsuccesfulrender,
      showError,
      [withLayout]
    );
  },
  streamdeletedsuccesfulrender: (result, args) => {
    const deletetrue = t("Deleted");
    const dashboarddelete = t("dashboard.succesfull.delete.toast");
    let success = t("success");
    Swal.fire(deletetrue, dashboarddelete, success);
    Dashboard.viewEnabledDashboards(result, args);
  },

  addDashboard: () => {
    if (
      Dashboard.enabled_dashboards_total == null ||
      Dashboard.enabled_dashboards_total === 0
    ) {
      Dashboard.extractDashboards(true);
    } else {
      Dashboard.extractDashboards(false);
    }
  },

  viewEnabledDashboards: (result, args) => {
    setTimeout(() => {
      if (args[0]) {
        const container = document.querySelector(".tab-content");
        const data = <DashboardWrapperContainer />;
        CommonTemplate.ContainerAvailability(container, data);
      } else {
        Dashboard.clearLayout();
        Dashboard.loadEnabledDashboardLayout(0);
      }
    }, 500);
  },

  extractAddDashboardData: () => {
    if (Dashboard.available_dashboard !== null) {
      return {
        tenant: Dashboard.available_dashboard.tenant,
        id: Dashboard.available_dashboard._id,
        type: "dashboards",
      };
    } else {
      showError(t("no.dashboard.message"));
      return null;
    }
  },

  extractDashboards: (withlayout) => {
    const payload = Dashboard.extractAddDashboardData();

    if (payload) {
      ToastPopup(t("enable.dashboard.toast"));
      ajax(
        "post",
        requestURI["enable.content"],
        null,
        payload,
        Dashboard.viewEnabledDashboards,
        showError,
        [withlayout]
      );
    } else {
      return null;
    }
  },

  clearLayout: () => {
    Dashboard.selected_dashboard = null;
    const container = document.querySelector("#bnfxDashboardLayout");
    container.innerHTML = "";
  },

  deleteConfirmation: (title) => {
    Swal.fire({
      title: t("delete.title.confirmation"),
      text: t("delete.description.confirmation") + title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("stream.delete.yes"),
    }).then((result) => {
      if (result.isConfirmed) {
        Dashboard.deleteDashboard();
      }
    });
  },

  bxCommonHeights: () => {
    let footer = document.querySelector("#bxFooter");
    let header = document.querySelector("#bxNavBar");

    var bxFooter_height = footer?.clientHeight || 0;

    var bxHeader_height = header?.clientHeight || 0;

    var windowHeight = window.innerHeight;
    var bxEnabledTotal_height =
      parseInt(bxHeader_height) + parseInt(bxFooter_height);
    var bxTabsHeight = parseInt(windowHeight) - parseInt(bxEnabledTotal_height);

    return bxTabsHeight;
  },

  adjustEnabledTabContentHeight: () => {
    var bxTabsHeight = Dashboard.bxCommonHeights();

    if (!document.querySelector(".bxStreamLeftSideBar_searchArea")) {
      return;
    }

    var bxTopSearch = document.querySelector(
      ".bxStreamLeftSideBar_searchArea"
    ).clientHeight;

    var bxListEnabledHeight = parseInt(bxTabsHeight) - parseInt(bxTopSearch);

    document.querySelector(".bxStreamLeftSideBar").style.minHeight =
      bxTabsHeight + "px";
    document.querySelector(".bxDList_box").style.minHeight =
      bxListEnabledHeight + "px";
    document.querySelector(".fullwidth").style.minHeight = bxTabsHeight + "px";

    return bxListEnabledHeight;
  },

  adjustAvailableTabContentHeight: () => {
    var bxAvailableTopSearch = document.querySelector(
      ".bxSuggestionTopBar"
    ).clientHeight;
    var bxAvailableFooter =
      document.querySelector(".offcanvas-footer").clientHeight;

    var bnfxAvailableWindowHeight = document.querySelector(
      ".bxAddStreamOffCanvas"
    ).clientHeight;
    var bnfxAvailableHeaderHeight =
      document.querySelector(".offcanvas-header").clientHeight;
    var bnfxAvailableStreamCount =
      document.querySelector(".bxSuggestionArea").clientHeight;
    var bxAvailableTotal_height =
      parseInt(bxAvailableFooter) + parseInt(bnfxAvailableHeaderHeight);
    var bxListAvailableTabsHeight =
      parseInt(bnfxAvailableWindowHeight) - parseInt(bxAvailableTotal_height);
    var bxListAvailableHeight =
      parseInt(bxListAvailableTabsHeight) -
      (parseInt(bxAvailableTopSearch) + parseInt(bnfxAvailableStreamCount));

    document.querySelector(".offcanvas-body").style.minHeight =
      bxListAvailableTabsHeight + "px";

    return bxListAvailableHeight;
  },

  adjustTabContentHeight: () => {
    var bxFooter_height = document.querySelector("#bxFooter").clientHeight;
    var bxHeader_height = document.querySelector("#bxNavBar").clientHeight;
    var windowHeight = window.innerHeight;
    var bxTotal_height = parseInt(bxHeader_height) + parseInt(bxFooter_height);
    var bxTabsHeight = parseInt(windowHeight) - parseInt(bxTotal_height);
    document.querySelector(".tab-content").style.minHeight =
      bxTabsHeight + "px";
  },

  openingpanelonclick: (rows, widget,data) => {
    Dashboard.widget_param = widget;
    let starttime =
      Dashboard.startDate !== null
        ? Dashboard.startDate
        : Dashboard.default_newstartDate !== null
        ? Dashboard.default_newstartDate
        : Dashboard.default_startDate;

    let endtime =
      Dashboard.endDate !== null
        ? Dashboard.endDate
        : Dashboard.default_newendDate !== null
        ? Dashboard.default_newendDate
        : Dashboard.default_endDate;

    if (rows["type"] === "timelinepanel") {
      let dataset = {
        name: rows.entity,
        column: rows.column,
        label: rows.label,
        data:data,
        rows:rows,
        columnmap:data.columnmap
      };
      
      Dashboard.leftSidePanelClick(dataset, widget.identifier);
    } else {
      let dashboardid = Dashboard.selected_dashboard?._id;
      let data = localStorage.getItem(
        `date_${dashboardid}bnfxWidgetDatePicker-${widget.identifier}`
      );
      let parsedValue = JSON.parse(data)?.range;

      PanelScript.loadrowOnclick(rows, starttime, endtime, parsedValue);
    }
  },

  leftSidePanelClick: (dataset, widgetid) => {
    let starttime =
      Dashboard.startDate !== null
        ? Dashboard.startDate
        : Dashboard.default_newstartDate !== null
        ? Dashboard.default_newstartDate
        : Dashboard.default_startDate;

    let endtime =
      Dashboard.endDate !== null
        ? Dashboard.endDate
        : Dashboard.default_newendDate !== null
        ? Dashboard.default_newendDate
        : Dashboard.default_endDate;
    let dashboardid = Dashboard.selected_dashboard?._id;
    let data = localStorage.getItem(
      `date_${dashboardid}bnfxWidgetDatePicker-${widgetid}`
    );
    let parsedValue = JSON.parse(data)?.range;

    const container = document.querySelector("#bnfxActionList");
    container.setAttribute("data-current-container", "dashboard");

    const renderdata = (
      <DashLeftSidePanel
        result={dataset.name}
        label={dataset.label}
        callback={() =>
          PanelScript.clickOnParticularPoint(
            dataset,
            starttime,
            endtime,
            parsedValue
          )
        }
      />
    );
    CommonTemplate.ContainerAvailability(container, renderdata);
  },
  setDefaultFilter: (widget, data, countfilter) => {
    try {
      const dataList = [];

      for (let key in data) {
        const values = data[key];
        dataList.push({ key, values });
      }

      if (dataList) {
        dataList.forEach((item, index) => {
          if (!Dashboard.widgetfilters[widget.identifier]) {
            Dashboard.widgetfilters[widget.identifier] = {};
          }
          Dashboard.widgetfilters[widget.identifier][item.key] = item.values;
        });
      }
    } catch (error) {
      console.error("Invalid JSON:", error);
    }

    const countelement = document.getElementById(
      "bnfxFSCount-" + widget.identifier
    );

    if (countfilter > 0) {
      countelement.innerText = countfilter;
      countelement.classList.remove("d-none");
    } else {
      countelement.innerText = countfilter;
      countelement.classList.add("d-none");
    }
  },

  applytypeasfilter: (filterid, values) => {
    const keys = Object.keys(Dashboard.widgetObjectMap);
    const widgetkey = keys.find((key) =>
      key.includes("top_outlier_threat_analysis")
    );
    const valuesList = Array.isArray(values) ? values : [values];

    if (!Dashboard.widgetfilters[widgetkey]) {
      Dashboard.widgetfilters[widgetkey] = {};
    }
    if (
      Dashboard.widgetfilters[widgetkey][filterid] &&
      Dashboard.widgetfilters[widgetkey][filterid][0] === valuesList[0]
    ) {
      delete Dashboard.widgetfilters[widgetkey][filterid];
    } else {
      Dashboard.widgetfilters[widgetkey][filterid] = valuesList;
    }
    Dashboard.renderWidgetData(Dashboard.widgetObjectMap[widgetkey], 0, false);
    let filtercount = 0;
    const widgetfilters = Dashboard.widgetfilters[widgetkey];

    for (let widgetfilterkey in widgetfilters) {
      filtercount += widgetfilters[widgetfilterkey].length;
    }

    const countelement = document.getElementById("bnfxFSCount-" + widgetkey);
    localStorage.setItem(`filtercount${widgetkey}`, JSON.parse(filtercount));

    if (filtercount > 0) {
      countelement.innerText = filtercount;
      countelement.classList.remove("d-none");
    } else {
      countelement.innerText = filtercount;
      countelement.classList.add("d-none");
    }
  },

  downloadPDF: async (title) => {
    const element = document.getElementById("bxDashboardWiget");
  
    if (element) {
      element.classList.add("downloadPdf");
    }
  
    element.style.position = 'relative';
  
    const pdf = new jsPDF("landscape", "px", "a3");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
  
    // Define the gradient colors
    const gradientStartColor = '#090b19'; // Starting color of the gradient
    const gradientEndColor = '#102a47'; // Ending color of the gradient
  
    // Function to add a gradient-like background to the entire page
    const addGradientBackground = (pdf, x = 0, y = 0) => {
      // Create a canvas to simulate the gradient background
      const canvas = document.createElement('canvas');
      canvas.width = pageWidth;
      canvas.height = pageHeight;
      const ctx = canvas.getContext('2d');
  
      // Create the gradient
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      gradient.addColorStop(0.4287, gradientStartColor); // Approximate the gradient percentage
      gradient.addColorStop(0.9967, gradientEndColor);
  
      // Fill the canvas with the gradient
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
  
      // Convert the canvas to an image and add it as the page background
      const imgData = canvas.toDataURL('image/jpeg');
      pdf.addImage(imgData, 'JPEG', x, y, pageWidth, pageHeight);
    };
  
    // Add gradient background to the first page
    addGradientBackground(pdf);
  
    // Get the elements and calculate their heights and positions
    let widgetHeightHolder = {};
    $(".grid-stack-item:visible").each((k, v) => {
      let eleHeight = $(v)[0].offsetHeight;
      let eleTop = $(v).offset().top;
  
      if (!widgetHeightHolder[eleTop]) {
        widgetHeightHolder[eleTop] = [];
      }
      widgetHeightHolder[eleTop].push({
        element: v,
        height: eleHeight,
        width: $(v)[0].offsetWidth,
      });
    });
  
    let currentPagePosition = 0;
    let currentHeight = 0;
  
    for (const key in widgetHeightHolder) {
      if (widgetHeightHolder.hasOwnProperty(key)) {
        const widgetsInRow = widgetHeightHolder[key];
  
        let rowTotalHeight = 0;
        let rowTotalWidth = 0;
  
        // Calculate the total width and height of the widgets in the current row
        for (let widgetData of widgetsInRow) {
          rowTotalHeight = Math.max(rowTotalHeight, widgetData.height); // Max height in the row
          rowTotalWidth += widgetData.width; // Total width of all widgets in the row
        }
  
        // Calculate scaling factor to fit the row widgets within the page width
        const scalingFactor = Math.min(1, pageWidth / rowTotalWidth);
  
        // Scale down the widgets' height and width if needed to fit them in the page width
        let currentRowX = 0; // Start position for widgets in the row
  
        for (let widgetData of widgetsInRow) {
          const widget = widgetData.element;
  
          // Render each widget into a canvas
          const canvas = await html2canvas(widget, {
            useCORS: true,
            scale: 2,
            backgroundColor: null, // Ensure the widget's canvas is transparent
          });
  
          const imgData = canvas.toDataURL("image/jpeg", 1.0);
  
          // const originalWidth = canvas.width;
          // const originalHeight = canvas.height;
  
          // Apply scaling factor to fit within the page width
          let imgWidth = widgetData.width * scalingFactor;
          let imgHeight = widgetData.height * scalingFactor;
  
          // Check if the row's total height fits in the current page height
          if (currentHeight + imgHeight > pageHeight) {
            pdf.addPage(); // Add new page if the row doesn't fit
            addGradientBackground(pdf); // Add gradient background to new page
            currentPagePosition = 0; // Reset position for the new page
            currentHeight = 0; // Reset current height
            currentRowX = 0; // Reset X position for the new page
          }
  
          // Add the widget image to the PDF at the calculated X and Y position
          pdf.addImage(imgData, "JPEG", currentRowX, currentPagePosition, imgWidth, imgHeight);
  
          // Update X position for the next widget in the row
          currentRowX += imgWidth;
        }
  
        // Update current position and height for the next row
        currentPagePosition += rowTotalHeight * scalingFactor;
        currentHeight += rowTotalHeight * scalingFactor;
      }
    }
  
    pdf.save(`${title}_Dashboard.pdf`);
  
    if (element) {
      element.classList.remove("downloadPdf");
    }
  },
  
  
  
  
  
};


